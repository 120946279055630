import styled from "styled-components";
export const SubHead = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-top: 15px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Left = styled.div``;
export const Right = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div`
  margin-top: 15px;
`;

export const Divider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin: 15px 0px;
`;
