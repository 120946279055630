import * as Styles from "./styles";
import type { RadioChangeEvent } from "antd";
import { Radio, Space } from "antd";
import { useState } from "react";

export default function FulfillmentMethod() {
  const [value, setValue] = useState(1);

  const onHandleRadioChange = (e: RadioChangeEvent) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <Styles.Container>
      <Styles.TabHead>Fulfillment Method</Styles.TabHead>
      <Styles.TabContent>
        <Radio.Group onChange={onHandleRadioChange} value={value}>
          <Space direction="vertical">
            <Radio value={1}>Discrete</Radio>
            <Radio value={2}>Wave</Radio>
            <Radio value={3}>Batch</Radio>
          </Space>
        </Radio.Group>
      </Styles.TabContent>
    </Styles.Container>
  );
}
