import * as Styles from "./styles";
import { useState } from "react";
import { Select } from "antd";

export default function PickStation() {
  const PickOptions = [
    {
      value: 1,
      label: "Pick Station 01",
    },
    {
      value: 2,
      label: "Pick Station 02",
    },
    {
      value: 3,
      label: "Pick Station 03",
    },
    {
      value: 4,
      label: "Pick Station 04",
    },
  ];
  const BinOptions = [
    {
      value: 1,
      label: "01",
    },
    {
      value: 2,
      label: "02",
    },
    {
      value: 3,
      label: "04",
    },
    {
      value: 4,
      label: "08",
    },
  ];
  const [pickValue, setPickValue] = useState<any>([PickOptions[0]]);
  const [binValue, setBinValue] = useState<any>([BinOptions[0]]);

  const onHandlePickSelect = (value: string) => {
    console.log(`selected ${value}`);
    setPickValue(value);
  };

  const onHandleBinSelect = (value: string) => {
    setBinValue(value);
  };

  return (
    <Styles.Container>
      <Styles.TabHead>Pick Station</Styles.TabHead>
      <Styles.TabContent className="test">
        <Styles.PickContainer>
          <Styles.PickLabel>Select Pick Station</Styles.PickLabel>
          <Select
            className="pick"
            defaultValue={pickValue}
            style={{ width: 250 }}
            onChange={onHandlePickSelect}
            options={PickOptions}
            popupClassName="pick-dropdown"
            showArrow={true}
          />
        </Styles.PickContainer>
        <Styles.PickDivider />
        <Styles.PickContainer>
          <Styles.PickLabel>Bins Per Pick Station</Styles.PickLabel>
          <Select
            className="pick"
            defaultValue={binValue}
            style={{ width: 250 }}
            onChange={onHandleBinSelect}
            options={BinOptions}
            popupClassName="pick-dropdown"
            showArrow={true}
          />
        </Styles.PickContainer>
      </Styles.TabContent>
    </Styles.Container>
  );
}
