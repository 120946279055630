import styled from "styled-components";

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;

export const Card = styled.div`
  width: 16.66%;
  min-height: 170px;
  border-right: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :last-child {
    border-right: 0;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #444444;
`;

export const Value = styled.div`
  font-weight: 700;
  font-size: 40px;
  color: #07a568;
`;
