import styled from "styled-components";
import { ReactComponent as HeaderIcon } from "./icons/header-logo.svg";
import { NavLink } from "react-router-dom";

export const DateContainer = styled.div`
  padding: 0px 20px;
`;

export const UserName = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #444444;
`;

export const Role = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #07a568;
`;

export const Container = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button.dropdown {
    display: flex;
    align-items: center;
    border-color: transparent;
    box-shadow: none;
    height: 64px;
    position: relative;
    gap: 10px;
    &:hover {
      background: #4d9a9a;
      border-color: #4d9a9a;
      border-radius: 0px 8px 8px 0px;
      ${UserName}, ${Role} {
        color: #ffffff;
      }
    }
    &::before {
      content: "";
      position: absolute;
      height: 45px;
      width: 1px;
      background: #f5f5f5;
      left: -5px;
    }
    ul {
      padding: 0px;
    }
  }
`;

export const HeaderLogo = styled(HeaderIcon)`
  height: 61px;
`;

export const Time = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #07a568;
  text-align: right;
`;

export const Date = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #444444;
  text-align: right;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const UserContainer = styled.div`
  text-align: left;
`;

export const UserImage = styled.img``;

export const Left = styled.div`
  height: 64px;
  display: flex;
`;
export const NavContainer = styled.nav`
  display: flex;
`;

export const NLink = styled(NavLink)`
  font-weight: 500;
  font-size: 20px;
  color: #003c3c;
  padding: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  height: 64px;
  min-width: 200px;

  :hover {
    color: #003c3c;
  }

  &.active {
    background: #1f5454;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
  }

  svg {
    fill: #003c3c;
  }
`;
