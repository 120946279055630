import styled from "styled-components";

export const Container = styled.div``;

export const TabHead = styled.div`
  font-weight: 600;
  font-size: 30px;
  color: #003c3c;
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  padding-bottom: 20px;
  padding-top: 5px;
`;

export const TabContent = styled.div`
  padding-top: 30px;

  .pick .ant-select-selection-item {
    padding-left: 10px;
  }

  .pick .ant-select-selector {
    border-color: transparent;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    color: rgb(68, 68, 68);
  }
  .pick:hover .ant-select-selector {
    border-color: transparent !important;
  }
  .pick.ant-select-focused .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .pick .ant-select-arrow {
    inset-inline-start: 0px;
    font-size: 14px;
    color: #07a568;
  }
`;

export const PickContainer = styled.div``;

export const PickLabel = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #003c3c;
  margin-bottom: 5px;
`;
export const PickDivider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin-top: 40px;
  margin-bottom: 15px;
`;
