import styled from "styled-components";

export const Container = styled.div`
  margin-top: 15px;
  .ant-tabs {
    gap: 15px;
    height: calc(100vh - 185px);
  }

  .ant-tabs-tabpane {
    padding-left: 15px;
  }
  .ant-tabs-nav,
  .ant-tabs-content-holder {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    padding: 15px 0px;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 20px;
    color: #444444;
  }

  .ant-tabs-tab-active {
    background: #e0fbee;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #444444 !important;
  }

  .ant-tabs-ink-bar {
    background: #07a568;
    width: 5px !important;
    left: 0px;
  }
`;
