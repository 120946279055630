import styled from "styled-components";
// import { ReactComponent as SelectedIcon } from "../../../../assets/icons/selected.svg";

export const Container = styled.div``;

export const Divider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin: 15px 0px;
`;
export const Header = styled.div`
  font-weight: 700;
  font-size: 25px;
  color: #003c3c;
  border-bottom: 1px solid #07a568;
  padding-bottom: 10px;
`;

export const SubMessage = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #444444;
  padding-top: 15px;
`;
export const Item = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;
export const Label = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #003c3c;
  width: 30%;
  text-align: right;
  padding-right: 10px;
`;
export const Value = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #666666;
  &.type1 {
    font-weight: 600;
  }
  &.Inactive {
    color: #f21167;
  }
  &.Active {
    color: rgb(7, 165, 104);
  }
`;
