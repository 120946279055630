import * as Styles from "./styles";
import { Modal } from "antd";

export interface FlagPopupProps {
  visible: boolean;
  isCloseDeletePopup: () => void;
  isSubmitDeletePopup: () => void;
  selectedDeleteData: any;
}

export default function DeletePopupBuilder({
  visible,
  isCloseDeletePopup,
  isSubmitDeletePopup,
  selectedDeleteData,
}: FlagPopupProps) {
  const onHandleDelete = () => {
    isSubmitDeletePopup();
  };

  return (
    <Styles.Container>
      <Modal
        wrapClassName="delete"
        open={visible}
        onOk={onHandleDelete}
        onCancel={isCloseDeletePopup}
        closable={false}
        cancelText="Cancel"
        okText="Delete"
      >
        <Styles.Header>Delete User</Styles.Header>
        <Styles.SubMessage>
          Are you sure you want to delete the selected user ?
        </Styles.SubMessage>
        <Styles.Divider />
        <Styles.Item>
          <Styles.Label>User:</Styles.Label>
          <Styles.Value className="type1">
            {selectedDeleteData?.name}
          </Styles.Value>
        </Styles.Item>
        <Styles.Item>
          <Styles.Label>User ID:</Styles.Label>
          <Styles.Value>{selectedDeleteData?.userId}</Styles.Value>
        </Styles.Item>
        <Styles.Item>
          <Styles.Label>Role:</Styles.Label>
          <Styles.Value className="type1">
            {selectedDeleteData?.role}
          </Styles.Value>
        </Styles.Item>
        <Styles.Item>
          <Styles.Label>Email:</Styles.Label>
          <Styles.Value>{selectedDeleteData?.email}</Styles.Value>
        </Styles.Item>
        <Styles.Item>
          <Styles.Label>Status:</Styles.Label>
          <Styles.Value className={selectedDeleteData?.status}>
            {selectedDeleteData?.status}
          </Styles.Value>
        </Styles.Item>
        <Styles.Item>
          <Styles.Label>Last Login:</Styles.Label>
          <Styles.Value>{selectedDeleteData?.lastLogin}</Styles.Value>
        </Styles.Item>
      </Modal>
    </Styles.Container>
  );
}
