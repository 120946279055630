import * as Styles from "./styles";

import { Outlet } from "react-router-dom";

export default function Listing() {
  return (
    <Styles.Container>
      <Outlet />
    </Styles.Container>
  );
}
