import { Layout } from "antd";
import { Routes, Route, Outlet, useLocation, Navigate } from "react-router-dom";
import * as Styles from "./styles";
import User from "./icons/user.svg";
import { Button, Dropdown } from "antd";
import { ReactComponent as ListingIcon } from "./icons/listing.svg";
import { ReactComponent as DashboardIcon } from "./icons/dashboard.svg";
import { format } from "date-fns";
import AppSettings from "../pages/dashboard/components/app-settings/AppSettings";
import Listing from "../pages/listing/Listing";
import Other from "../pages/other/Other";
import Users from "../pages/listing/users/Users";
import Dashboard from "../pages/dashboard/Dashboard";

function App() {
  const { Header, Content, Footer } = Layout;
  const location = useLocation();

  const onHandleSignOutClick = () => {
    window.location.replace("https://carteplus-ui-landing-test.azureedge.net/");
  };

  const current = new Date();
  const date = format(current, "MMM dd, yyyy");

  const time = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const items = [
    {
      key: "1",
      label: (
        <div>
          <a className="sign-out" onClick={onHandleSignOutClick}>
            Sign Out
          </a>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Header>
        <Styles.Container>
          <Styles.Left>
            <Styles.HeaderLogo />
            <Styles.NavContainer>
              <Styles.NLink
                to="/dashboard"
                className={
                  location?.pathname === "dashboard" ||
                  location?.pathname === "/"
                    ? "active"
                    : "none"
                }
              >
                <DashboardIcon />
                Dashboard
              </Styles.NLink>
              <Styles.NLink to="/listing">
                <ListingIcon />
                Listings
              </Styles.NLink>
            </Styles.NavContainer>
          </Styles.Left>
          <Styles.Right>
            <Styles.DateContainer>
              <Styles.Time>{time}</Styles.Time>
              <Styles.Date>{date}</Styles.Date>
            </Styles.DateContainer>
            <Dropdown
              className="dropdown"
              menu={{
                items,
              }}
              placement="bottomRight"
              overlayClassName="header-dropdown"
            >
              <Button>
                <Styles.UserImage src={User} />
                <Styles.UserContainer>
                  <Styles.UserName>John Doe</Styles.UserName>
                  <Styles.Role>Admin</Styles.Role>
                </Styles.UserContainer>
              </Button>
            </Dropdown>
          </Styles.Right>
        </Styles.Container>
      </Header>
      <Routes>
        <Route path="/dashboard/*" element={<Dashboard />}>
          <Route
            index
            element={<Navigate to="carte-configuration" replace />}
          />
          <Route path=":source" element={<AppSettings />} />
        </Route>
        <Route path="/listing/*" element={<Listing />}>
          <Route index element={<Navigate to="user-listing" replace />} />
          <Route path="user-listing" element={<Users />} />
          <Route path="other" element={<Other />} />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
      <Content className="site-layout">
        <Outlet />
      </Content>
      <Footer>All rights reserved © 2024 Cartesian Kinetics</Footer>
    </Layout>
  );
}

export default App;
