import * as Styles from "./styles";
import { Space, Table, Tag } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { ReactComponent as EditIcon } from "./icons/edit.svg";
import { ReactComponent as DeleteIcon } from "./icons/delete.svg";
import { ReactComponent as UserIcon } from "./icons/user.svg";
import { ReactComponent as FilterIcon } from "./icons/filter.svg";
import { useState } from "react";

interface DataType {
  // key: string;
  userId: string;
  name: string;
  email: string;
  role: string;
  status: string;
  lastLogin: string;
}

export interface UserDetailsProps {
  isOpenEditPopup: (status: boolean) => void;
  isOpenDeletePopup: (status: boolean, selectedData: any) => void;
}

export default function UserDetails({
  isOpenEditPopup,
  isOpenDeletePopup,
}: UserDetailsProps) {
  const onHandleEditPopup = () => {
    isOpenEditPopup(true);
  };

  const onHandleDeletePopup = (selectedData: any) => {
    isOpenDeletePopup(true, selectedData);
  };

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    // console.log("params", pagination, filters, sorter, extra);
  };

  const columns: ColumnsType<DataType> = [
    {
      key: "userId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "userId"
        );
        return (
          <Styles.ArrowContainer>
            User ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      dataIndex: "userId",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      dataIndex: "name",
      key: "name",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "name"
        );
        return (
          <Styles.ArrowContainer>
            Name
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },

      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.name.length - b.name.length,
      render: (nameText) => (
        <Styles.Name>
          <UserIcon /> {nameText}
        </Styles.Name>
      ),
    },

    {
      dataIndex: "email",
      key: "email",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "email"
        );
        return (
          <Styles.ArrowContainer>
            Email
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },

      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      dataIndex: "role",
      key: "role",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "role"
        );
        return (
          <Styles.ArrowContainer>
            Role
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },

      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.role.length - b.role.length,
      filters: [
        {
          text: "Operator",
          value: "Operator",
        },
        {
          text: "Admin",
          value: "Admin",
        },
        {
          text: "Custom",
          value: "Custom",
        },
      ],
      onFilter: (value: any, record) => record.role.indexOf(value) === 0,
      filterIcon: (filtered) => <FilterIcon />,
    },
    {
      dataIndex: "status",
      key: "status",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "status"
        );
        return (
          <Styles.ArrowContainer>
            Status
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },

      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.status.length - b.status.length,
      render: (statusText) => (
        <Styles.Status active={statusText === "Active" ? true : false}>
          {statusText}
        </Styles.Status>
      ),
      filters: [
        {
          text: "Active",
          value: "Active",
        },
        {
          text: "Inactive",
          value: "Inactive",
        },
      ],
      onFilter: (value: any, record) => record.status.indexOf(value) === 0,
      filterIcon: (filtered) => <FilterIcon />,
    },
    {
      dataIndex: "lastLogin",
      key: "lastLogin",
      width: "19%",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "lastLogin"
        );
        return (
          <Styles.ArrowContainer>
            Last Login
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },

      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.lastLogin.length - b.lastLogin.length,
    },
    {
      title: "Quick Actions",
      dataIndex: "",
      key: "X",
      render: (record) => {
        return (
          <>
            <Styles.Actions>
              <Styles.Icon onClick={onHandleEditPopup}>
                <EditIcon />
              </Styles.Icon>
              <Styles.Icon onClick={() => onHandleDeletePopup(record)}>
                <DeleteIcon />
              </Styles.Icon>
            </Styles.Actions>
          </>
        );
      },
    },
  ];

  const data: DataType[] = [
    {
      userId: "UID1100",
      name: "SSS",
      email: "sss@mail.com",
      role: "Operator",
      status: "Inactive",
      lastLogin: "Today, 10:20:PM",
    },
    {
      userId: "AID1101",
      name: "AAA",
      email: "aaa@mail.com",
      role: "Admin",
      status: "Active",
      lastLogin: "Today, 10:20:AM",
    },
    {
      userId: "UID1102",
      name: "test",
      email: "test@mail.com",
      role: "Custom",
      status: "Inactive",
      lastLogin: "Yesterday, 10:20:PM",
    },
  ];

  return (
    <Styles.Container>
      <Styles.Head>
        <Styles.Title>User Details</Styles.Title>
      </Styles.Head>
      <Table
        tableLayout="fixed"
        rowKey="userId"
        columns={columns}
        dataSource={data}
        pagination={false}
        className="process"
        onChange={onChange}
        showSorterTooltip={false}
      />
    </Styles.Container>
  );
}
