import React, { useState } from "react";
import * as Styles from "./styles";
import { Select, message, notification } from "antd";
import { ReactComponent as DownIcon } from "../../../assets/down-arrow.svg";
import { ReactComponent as AddIcon } from "./icons/add.svg";
import { Outlet, useNavigate } from "react-router-dom";
import TotalCard from "./components/total-card/TotalCard";
import UserDetails from "./components/user-details/UserDetails";
import AddPopupBuilder from "./components/add-popup-builder/AddPopupBuilder";
import EditPopupBuilder from "./components/edit-popup-builder/EditPopupBuilder";
import DeletePopupBuilder from "./components/delete-popup-builder/DeletePopupBuilder";

export default function Listing() {
  const [showAddPopupBuilder, setShowAddPopupBuilder] = useState(false);
  const [showEditPopupBuilder, setShowEditPopupBuilder] = useState(false);
  const [showDeletePopupBuilder, setShowDeletePopupBuilder] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { Option } = Select;
  const data = ["User Listing", "Other"];
  const [listSelectedValue, setListSelectedValue] = useState("user_listing");
  const [selectedDeleteData, setSelectedDeleteData] = useState(null);
  const navigate = useNavigate();

  //toast messages
  const userAddMessage = () => {
    notification.open({
      message: "New User Successfully Added !",
      className: "user",
      placement: "bottomLeft",
    });
  };

  const userDeleteMessage = () => {
    notification.open({
      message: "Selected User Successfully Deleted !",
      className: "user",
      placement: "bottomLeft",
    });
  };

  const userUpdateMessage = () => {
    notification.open({
      message: "Selected User Successfully Updated !",
      className: "user",
      placement: "bottomLeft",
    });
  };

  // add new user popup
  const isOpenAddPopup = () => {
    setShowAddPopupBuilder(true);
  };

  const isCloseAddPopup = () => {
    setShowAddPopupBuilder(false);
  };

  const isSubmitAddPopup = () => {
    setShowAddPopupBuilder(false);
    userAddMessage();
  };

  // edit user popup
  const isOpenEditPopup = (status: boolean) => {
    setShowEditPopupBuilder(status);
  };

  const isCloseEditPopup = () => {
    setShowEditPopupBuilder(false);
  };

  const isSubmitEditPopup = () => {
    setShowAddPopupBuilder(false);
    userUpdateMessage();
  };

  // delete user popup
  const isOpenDeletePopup = (status: boolean, selectedData: any) => {
    setShowDeletePopupBuilder(status);
    setSelectedDeleteData(selectedData);
  };

  const isCloseDeletePopup = () => {
    setShowDeletePopupBuilder(false);
  };

  const isSubmitDeletePopup = () => {
    setShowAddPopupBuilder(false);
    userDeleteMessage();
  };

  const handleChange = (value: string) => {
    if (value === "user_listing") {
      navigate("user-listing");
    } else {
      navigate("other");
    }
  };

  return (
    <>
      <Styles.Container>
        <Styles.SubHead>
          <Select
            showArrow={false}
            bordered={false}
            className="common"
            popupClassName="pick-dropdown"
            // defaultValue="user_listing"
            defaultValue="User Listing"
            onChange={handleChange}
            options={[
              { value: "user_listing", label: "User Listing" },
              { value: "other", label: "Other" },
            ]}
          />
          {listSelectedValue === "user_listing" && (
            <Styles.Right onClick={isOpenAddPopup}>
              <AddIcon />
              Add New
            </Styles.Right>
          )}
        </Styles.SubHead>
        <TotalCard />
        <Styles.Divider />
        <UserDetails
          isOpenEditPopup={isOpenEditPopup}
          isOpenDeletePopup={isOpenDeletePopup}
        />
      </Styles.Container>
      <AddPopupBuilder
        visible={showAddPopupBuilder}
        isCloseAddPopup={isCloseAddPopup}
        isSubmitAddPopup={isSubmitAddPopup}
      />
      <EditPopupBuilder
        visible={showEditPopupBuilder}
        isCloseEditPopup={isCloseEditPopup}
        isSubmitEditPopup={isSubmitEditPopup}
      />
      <DeletePopupBuilder
        visible={showDeletePopupBuilder}
        isCloseDeletePopup={isCloseDeletePopup}
        isSubmitDeletePopup={isSubmitDeletePopup}
        selectedDeleteData={selectedDeleteData}
      />
      <>{contextHolder}</>
    </>
  );
}
