import { useNavigate } from "react-router-dom";
import SubHeader from "../../../../components/sub-header/SubHeader";
import CarteConfiguration from "../carte-configuration/CarteConfiguration";
import FulfillmentMethod from "../fulfillment-method/FulfillmentMethod";
import OrderIntegration from "../order-integration/OrderIntegration";
import PickStation from "../../../pick-station/PickStation";
import * as Styles from "./styles";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

export default function AppSettings() {
  const navigate = useNavigate();

  const items: TabsProps["items"] = [
    {
      key: "carte-configuration",
      label: `Carte+ Configuration`,
      children: <CarteConfiguration />,
    },
    {
      key: "pick-station",
      label: `Pick Station`,
      children: <PickStation />,
    },
    {
      key: "fulfillment-method",
      label: `Fulfillment Method`,
      children: <FulfillmentMethod />,
    },
    {
      key: "order-integration",
      label: `Order Integration`,
      children: <OrderIntegration />,
    },
  ];

  const OnHandleTabChange = (key: string) => {
    navigate(`../${key}`);
  };

  return (
    <Styles.Container>
      <SubHeader />
      <Tabs
        tabPosition="left"
        defaultActiveKey="carte-configuration"
        items={items}
        onChange={OnHandleTabChange}
      />
    </Styles.Container>
  );
}
