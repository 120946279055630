import * as Styles from "./styles";
import {
  Modal,
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { useState } from "react";
import type { UploadProps } from "antd";
import { ReactComponent as UploadIcon } from "../../icons/upload.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

export interface FlagPopupProps {
  visible: boolean;
  isCloseAddPopup: () => void;
  isSubmitAddPopup: () => void;
}

export default function AddPopupBuilder({
  visible,
  isCloseAddPopup,
  isSubmitAddPopup,
}: FlagPopupProps) {
  const [switchValue, setSwitchValue] = useState(false);
  const [imageStatus, setImageStatus] = useState<any>(null);
  const [fileMessage, setFileMessage] = useState("");
  const onChange = (checkedValues: CheckboxValueType[]) => {
    // console.log("checked = ", checkedValues);
  };
  const props: UploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setImageStatus(info?.file);
      if (info.file.status !== "uploading") {
        setFileMessage("file Uploading!");
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setFileMessage("file uploaded successfully!");
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        setFileMessage("file upload failed!");
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Styles.Container>
      <Modal
        wrapClassName="user"
        open={visible}
        onOk={isSubmitAddPopup}
        onCancel={isCloseAddPopup}
        closable={false}
        cancelText="Cancel"
        okText="Add"
      >
        <Styles.Header>
          Add New User <CloseIcon onClick={isCloseAddPopup} />
        </Styles.Header>
        <Form className="user" layout="horizontal">
          <Styles.FormItem imageStatus={imageStatus?.status}>
            <Upload
              {...props}
              listType="picture-circle"
              className="user-upload"
            >
              <UploadIcon />
            </Upload>
            {/* <Styles.Message>{fileMessage}</Styles.Message> */}
          </Styles.FormItem>
          <Styles.Divider />
          <Styles.FormItem className="padding">
            <Styles.Label>Name</Styles.Label>
            <Input value="Random User 01" className="user-input" />
          </Styles.FormItem>
          <Styles.FormItem className="padding">
            <Styles.Label>Email</Styles.Label>
            <Input value="userid123@mail.com" className="user-input" />
          </Styles.FormItem>
          <Styles.FormItem>
            <Styles.Label>Role</Styles.Label>
            <Radio.Group className="role-radio">
              <Radio value="operator"> Operator </Radio>
              <Radio value="supervisor"> Supervisor </Radio>
              <Radio value="admin"> Admin </Radio>
              <Radio value="custom"> Custom </Radio>
            </Radio.Group>
          </Styles.FormItem>
          <Styles.Divider />
          <Styles.FormItem>
            <Checkbox.Group onChange={onChange} className="role-checkbox">
              <Checkbox value="dashboard">Dashboard </Checkbox>
              <Checkbox value="order-fulfillment">Order Fulfillment</Checkbox>
              <Checkbox value="material-inward">Material Inward</Checkbox>
              <Checkbox value="listing">Listing</Checkbox>
            </Checkbox.Group>
          </Styles.FormItem>
          <Styles.Divider />
          <Styles.FormItem>
            <Styles.Status active={switchValue}>
              <Styles.Label>User Status:</Styles.Label>
              <Switch
                className="role-switch"
                size="small"
                onChange={(value) => {
                  setSwitchValue(value);
                }}
              />
              {switchValue === true ? "Active" : "Inactive"}
            </Styles.Status>
          </Styles.FormItem>
        </Form>
      </Modal>
    </Styles.Container>
  );
}
