import styled from "styled-components";

export const Container = styled.div``;

export const TabHead = styled.div`
  font-weight: 600;
  font-size: 30px;
  color: #003c3c;
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  padding-bottom: 20px;
  padding-top: 5px;
`;

export const TabContent = styled.div`
  padding-top: 30px;

  .ant-radio-wrapper {
    font-weight: 500;
    font-size: 20px;
    color: #444444;
    padding-bottom: 10px;
  }

  .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #07a568;
  }

  .ant-radio-wrapper-checked {
    color: #07a568;
  }

  .ant-radio-wrapper-checked .ant-radio-inner {
    border-color: #07a568;
    background-color: #fff;
  }

  .ant-radio-wrapper-checked .ant-radio-inner:hover {
    border-color: red;
  }

  .ant-radio-wrapper-checked .ant-radio-inner::after {
    background-color: #07a568;
    width: 20px;
    height: 20px;
    margin-block-start: -10px;
    margin-inline-start: -10px;
  }
`;
