import * as Styles from "./styles";

export default function TotalCard() {
  const CARD_DATA = [
    { title: "No. Of Users", value: "110" },
    { title: "Available Roles", value: "04" },
    { title: "No. Of Admins", value: "02" },
    { title: "No. Of Supervisors", value: "08" },
    { title: "No. Of Operators", value: "80" },
    { title: "No. Of Custom Users", value: "20" },
  ];
  return (
    <Styles.Container>
      {CARD_DATA?.map((item, index) => {
        return (
          <Styles.Card key={index}>
            <Styles.Title>{item?.title}</Styles.Title>
            <Styles.Value>{item?.value}</Styles.Value>
          </Styles.Card>
        );
      })}
    </Styles.Container>
  );
}
