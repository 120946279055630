import styled from "styled-components";
// import { ReactComponent as SelectedIcon } from "../../../../assets/icons/selected.svg";

export const Container = styled.div``;
export const Message = styled.div``;

export const Label = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #1f5454;
`;
export const FormItem = styled.div<{ imageStatus?: string }>`
  &.padding {
    margin-bottom: 20px;
  }
  .ant-upload.ant-upload-select {
    display: ${(props) => (props.imageStatus === "done" ? "none" : "")};
  }
  .ant-upload-list-item-container {
    display: none;
  }
  .ant-upload-list-item-container {
    display: ${(props) =>
      props.imageStatus === "error" ? "none !important" : ""};
  }
`;

export const Status = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14spx;
  color: ${(props) => (props.active === true ? "#444444" : "#666")};
`;

export const Divider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin: 15px 0px;
`;
export const Header = styled.div`
  position: absolute;
  width: 96%;
  top: -60px;
  left: 0;
  background: #1f5454;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  min-height: 50px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: 20px;
  svg {
    cursor: pointer;
  }
`;
