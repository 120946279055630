import styled from "styled-components";

export const Container = styled.div`
  background: #1f5454;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 0px 15px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
`;
