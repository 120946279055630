import styled from "styled-components";
import { ReactComponent as Arrow } from "./icons/arrow.svg";

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  .process {
    thead th {
      background: #f3fdf8;
      font-weight: 600;
      font-size: 18px;
      color: #1f5454;
      border-bottom: 0px;
      &::before {
        width: 0px !important;
      }
    }

    tbody td {
      font-weight: 500;
      font-size: 18px;
      color: #666666;
      border-bottom: 0px;
    }
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const ArrowDownIcon = styled(Arrow)`
  fill: #07a568;
`;
export const ArrowUpIcon = styled(Arrow)`
  fill: #888888;
  transform: rotate(180deg);
`;

export const Head = styled.div`
  padding: 15px 10px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #444444;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 25px;
  gap: 25px;
`;
export const Icon = styled.div`
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
`;
export const Status = styled.div<{ active?: boolean }>`
  padding: 0px 7px;
  text-align: center;
  border-radius: 20px;
  min-height: 30px;
  min-width: 80px;
  color: ${(props) => (props.active === true ? "#07A568" : "#F21167")};
  background: ${(props) => (props.active === true ? "#E0FBEE" : "#FFBDD6")};
`;
export const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
